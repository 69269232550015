<template>
  <main class="mr-4 :md:mr-0 mb-4">
    <trac-loading v-if="isLoading" />
    <div v-if="isACleanSlate">
      <trac-dual-sync
        :status="'syncing'"
        :progress="progress"
      ></trac-dual-sync>
    </div>

    <div class="" v-if="stores">
      <div class="flex items-center">
        <ul class="flex-col mt-12 mb-8">
          <li>Stores</li>
          <li class="font-bold mt-4 text-xl">All Stores</li>
        </ul>
        <div class="flex-1"></div>
        <div class="flex mr-8 flex-row items-center rounded big-shadow justify-between px-2 bg-white">
            <span class="text-xs font-light text-primaryGray w-full">
                <input data-test="search-stores" v-model="searchTerm" type="text" name="" id="" placeholder="Search store..." class="w-full py-2 px-4 outline-none">
            </span>
            <img src="../../assets/svg/Search_icon.svg">
        </div>
        <div class="">
          <trac-button data-test="add-address" @button-clicked="$router.push({ name: 'AddStore' })"
            >Add Store</trac-button
          >
        </div>
      </div>

      <div class="big-shadow">
        <table class="w-full table-auto">
          <thead class="bg-blue-100">
            <tr>
              <th class="text-left p-4 text-sm font-semibold uppercase">
                Store name
              </th>
              <th class="text-left p-4 text-sm font-semibold uppercase">
                State
              </th>
              <th class="text-left p-4 text-sm font-semibold uppercase">
                Phone
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              @click="gotoEditStore(store)"
              v-for="(store, i) in filteredStores"
              :key="i"
              :class="i % 2 === 0 ? 'bg-white' : 'bg-gray-100'"
              class="hover:bg-gray-200 cursor-pointer"
            >
              <td class="p-4 text-xs font-bold capitalize">{{ store.name }}</td>
              <td class="p-4 text-xs capitalize">{{ store.state }}</td>
              <td class="p-4 text-xs">{{ store.phone }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<script>
import AdvancedSearch from "../../offline-module/advancedSearch";
import { filterOnlyAssigned } from "../../offline-module/storeFilter"
import Pagination from "vue-pagination-2";
import { cleanSlate } from "../../offline-module/offline.store";
import {
  DELETE_LOCAL_DB_DATA,
  SAVE_LOCAL_DB_DATA,
  GET_LOCAL_DB_DATA,
  GET_USER_BUSINESS_ID
} from "../../browser-db-config/localStorage";

export default {
  data() {
    return {
      isLoading: false,
      stores: [],
      totalRecords: 0,
      page: 0,
      searchTerm: '',
      adv: new AdvancedSearch({
        BusinessId: GET_USER_BUSINESS_ID(),
        offlineManager: this.$GlobalOfflineManager,
      })
    };
  },
  watch: {
    SWData(newValue, oldValue) {
      console.log("Stores -> SWData, ", newValue);
      if (this.isADownloadOnlySyncEvent(newValue, 'stores')) {
        console.log('Download Sync for Stores complete')
        cleanSlate.moduleList["stores"] = false;
        this.loadOfflineRecords();
      }

      if (this.isAProgressSyncEvent(newValue, 'stores')) {
        this.progress = newValue.data;
      }
    },
  },
  async created() {
    DELETE_LOCAL_DB_DATA("store-edit-details");
    await this.NetworkCheck();
    // this.dualSyncStatus = "syncing";
    // this.loadOfflineRecords();
    this.isLoading = true
    this.getFullStoreData().then((list) => {
      this.stores = list
      this.isLoading = false
    }).catch((err) => {
      this.isLoading = false
    })
    // this.stores = this.getAssignedStoresFromSession()
    // this.isLoading = true;
    // await this.fetchAllStores();
    // this.isLoading = false;
  },
  methods: {
    async getFullStoreData() {
      // fetch all stores from the business
      const fetchStores = await this.$store.dispatch('HARD_FETCH_ALL_STORES').catch((err) => {
        // do some error catching
      })
      if(fetchStores.status) {
        const storeList = fetchStores.data || []
        // get all stores from session
        const assignedStores = this.getAssignedStoresFromSession()
        // debugger
        // for each store in session, find the store detail from all stores and format for use as list
        const stores = assignedStores.map((store) => {
          const assignedStore = storeList.find(item => item._id === store.id)
          return assignedStore
          // debugger
        })
        return stores
      } else {
        return []
      }
      
    },
    gotoEditStore(store) {
      SAVE_LOCAL_DB_DATA("store-edit-details", store);
      this.$router.push({ name: "AddStore" });
    },
    async fetchAllStores() {
      this.isLoading = true;
      await this.$store.dispatch("FETCH_ALL_STORES");
      const res = this.$store.getters["GET_ALL_STORES"];

      if (res.status) {
        this.stores = res.data || [];
      }
      this.isLoading = false;
    },
    async loadOfflineRecords() {
      // console.log("loadOfflineRecords() called");
      // const allStores = await this.$GlobalOfflineManager.getAll('stores')
      // const assignedStores = filterOnlyAssigned(allStores, '_id') || []
      const assignedStores = this.getAssignedStoresFromSession()
      // this.totalRecords = await this.adv.getTotalRecords("stores")
      this.totalRecords = assignedStores.length
      this.stores = assignedStores
      this.searchTerm = ''
      this.isLoading = false
      // debugger
    },
    getAssignedStoresFromSession() {
      const sessionData = GET_LOCAL_DB_DATA('traction-app-user-data')
      const stores = (sessionData || {user : {stores : []}}).user.stores || []
      // this.stores = stores
      return stores
    }
  },
  computed: {
    isACleanSlate() {
      return cleanSlate.moduleList["stores"] === true;
    },
    filteredStores() {
      return this.stores.filter(store => store.name.toLowerCase().includes(this.searchTerm.toLowerCase()))
    }
  },
};
</script>

<style></style>
